import * as React from 'react'

import City from './City';
import { CLOCKS_PER_PAGE } from './constants'
import rightArrow from './images/right-arrow.svg'
import * as Styles from './styles'

interface Clock {
  city: string,
  timezone: string,
  accuWeatherLocationKey: string,
}

// We want to set the type of `CLOCKS`, so we'll use a `require()` statement
// rather than `import`.
const CLOCKS: Clock[] = require('./clocks.json') // tslint:disable-line:no-var-requires

const WorldClock = () => {
  const [page, setPage] = React.useState(1)

  const cyclePage = (pageNumber: number) => {
    if (Math.ceil(CLOCKS.length / CLOCKS_PER_PAGE) < pageNumber) {
      setPage(1)
    } else {
      setPage(pageNumber)
    }
  }

  return (
    <Styles.Section>
      <Styles.UlWrapper>
        <Styles.Ul page={page}>
          {CLOCKS.map(({ city, timezone, accuWeatherLocationKey }) => (
            <City
              key={city}
              city={city}
              timezone={timezone}
              accuWeatherLocationKey={accuWeatherLocationKey}
            />
          ))}
        </Styles.Ul>
      </Styles.UlWrapper>
      <Styles.Arrow src={rightArrow} onClick={() => cyclePage(page + 1)} />
    </Styles.Section>
  )
}

export default WorldClock
