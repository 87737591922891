import * as React from 'react'

import * as Styles from './styles'

const isValidDomain = (url: string): boolean =>
  // https://regexr.com/3au3g
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/g.test(url)

const GoogleSearch = () => {
  const searchInputRef = React.createRef<HTMLInputElement>()

  const handleSubmit = (event: React.FormEvent) => {
    // If the user entered a valid domain, navigate to that URL. Otherwise,
    // allow the default form handling to occur (i.e., search Google using the
    // form's `action` attribute).
    if (searchInputRef.current && isValidDomain(searchInputRef.current.value)) {
      window.open(`http://${searchInputRef.current.value}`, '_blank')
      event.preventDefault()
    }
  }

  return (
    <Styles.Form action="https://google.com/search" onSubmit={handleSubmit} target="_blank">
      <Styles.Input name="q" autoFocus={true} ref={searchInputRef} placeholder="Search Google or type a URL" />
    </Styles.Form>
  )
}

export default GoogleSearch
