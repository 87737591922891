export const CALENDAR_TOTAL_DAYS = 31
export const CALENDAR_TOTAL_DAYS_MOBILE = 3

// To fit the layout, we can only display this many days' worth of events
export const CALENDAR_DAYS_PER_VIEW = 3

/**
 * The maximum views/"pages" of calendar events. That is, if there are 3 events
 * per view, and there are 31 days' worth of events, this will be equal to 11.
 */
export const CALENDAR_TOTAL_VIEWS = Math.ceil(CALENDAR_TOTAL_DAYS / CALENDAR_DAYS_PER_VIEW)

export const CALENDAR_MONTH_HEIGHT = '2.5rem'

export const CALENDAR_INDEX_TO_DOT = [
  '#1549FF',
  '#FF7715',
]
