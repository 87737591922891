import { createGlobalStyle } from 'styled-components'

import { FontWeight } from '@lib/typography'

import book from './fonts/Neutraface-Slab-Text-Book.otf'
import demi from './fonts/Neutraface-Slab-Text-Demi.otf'
import bold from './fonts/Neutraface-Slab-Text-Bold.otf'

/**
 * Weight name mapping taken from
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping
 */
const FontsCSS = createGlobalStyle`
@font-face {
  font-family: 'Neutraface Slab';
  font-weight: ${FontWeight.Book};
  src: url("${book}") format("opentype");
}

@font-face {
  font-family: 'Neutraface Slab';
  font-weight: ${FontWeight.Demibold};
  src: url("${demi}") format("opentype");
}

@font-face {
  font-family: 'Neutraface Slab';
  font-weight: ${FontWeight.Bold};
  src: url("${bold}") format("opentype");
}
`

export default FontsCSS
