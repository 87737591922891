/**
 * Accuweather includes a `WeatherIcon` property in its response, which
 * correlates to one of the icons here:
 * https://developer.accuweather.com/weather-icons. This file contains the paths
 * of weather icon for which we have icons.
 */
export const WEATHER_ICONS: { [key: number]: string } = {
  1: require('./images/1.svg'),
  2: require('./images/2.svg'),
  3: require('./images/3.svg'),
  4: require('./images/4.svg'),
  5: require('./images/5.svg'),
  6: require('./images/6.svg'),
  7: require('./images/7.svg'),
  8: require('./images/8.svg'),


  11: require('./images/11.svg'),
  12: require('./images/12.svg'),
  13: require('./images/13.svg'),

  15: require('./images/15.svg'),
  16: require('./images/16.svg'),

  18: require('./images/18.svg'),
  19: require('./images/19.svg'),
  20: require('./images/20.svg'),

  22: require('./images/22.svg'),
  23: require('./images/23.svg'),
  24: require('./images/24.svg'),
  25: require('./images/25.svg'),
  26: require('./images/26.svg'),



  30: require('./images/30.svg'),
  31: require('./images/31.svg'),
  32: require('./images/32.svg'),
  33: require('./images/33.svg'),
  34: require('./images/34.svg'),
  35: require('./images/35.svg'),
  36: require('./images/36.svg'),
  37: require('./images/37.svg'),
  38: require('./images/38.svg'),

  40: require('./images/40.svg'),
  41: require('./images/41.svg'),
  42: require('./images/42.svg'),
  43: require('./images/43.svg'),
  44: require('./images/44.svg'),
}
