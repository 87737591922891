/**
 * The GoogleService components are a pair of React context provider and
 * consumer. The provider should be wrapped around the entire app, and the
 * consumer can be used to A) get the currently logged-in user, B) set the
 * currently logged-in user (e.g., after a login event), and C) get calendar
 * events. It also providers `isLoading` and `showLogin` properties, so that
 * consumer components can determine which views to show.
 */
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import moment from 'moment'
import * as React from 'react'

import { CalendarEventsByMonthAndDay, GoogleCalendarEvents, GoogleServiceContext, User } from './types'
import { CALENDAR_TOTAL_DAYS } from '@components/Calendar/constants';
import { getCalendarEventsByMonthAndDay, flattenAndSortCalendars } from './helpers'

const userIsGoogleLoginResponse =
  (response: GoogleLoginResponse | GoogleLoginResponseOffline): response is GoogleLoginResponse =>
    'getAuthResponse' in response

export const Context = React.createContext<GoogleServiceContext>({
  setUser: user => Promise.resolve(),
  getUser: () => undefined,
  showLogin: false,
  isLoading: false,
})

interface State {
  isLoading: boolean
  showLogin: boolean
  calendarEventsByMonthAndDay?: CalendarEventsByMonthAndDay
  user?: User
}

export class Provider extends React.Component<{}, State> {
  public state: State = {
    isLoading: true,
    showLogin: false,
  }

  constructor(props: {}) {
    super(props)

    this.setUser = this.setUser.bind(this)
    this.getUser = this.getUser.bind(this)
  }

  public componentDidMount() {
    this.fetchCalendarEvents()
  }

  public render() {
    return (
      <Context.Provider
        value={{
          setUser: this.setUser,
          getUser: this.getUser,
          isLoading: this.state.isLoading,
          showLogin: this.state.showLogin,
          calendarEventsByMonthAndDay: this.state.calendarEventsByMonthAndDay,
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }

  private async setUser(user: GoogleLoginResponse | GoogleLoginResponseOffline): Promise<void> {
    if (typeof window === 'undefined' || !userIsGoogleLoginResponse(user)) { return Promise.reject() }

    const givenName = user.getBasicProfile().getGivenName()
    const accessToken = user.getAuthResponse().access_token
    const idToken = user.getAuthResponse().id_token

    // Save to local storage for next page reload (see also `.getUser()`)
    window.localStorage.setItem('onDashboard.user.givenName', givenName)
    window.localStorage.setItem('onDashboard.user.accessToken', accessToken)
    window.localStorage.setItem('onDashboard.user.idToken', idToken)

    // We also need to set the state, so that components using the context
    // consumer will be re-rendered.
    await this.setState({
      user: { givenName, accessToken, idToken },
      showLogin: false,
    })

    return this.fetchCalendarEvents()
  }

  private getUser() {
    if (typeof window === 'undefined') { return undefined }

    const givenName = window.localStorage.getItem('onDashboard.user.givenName')
    const accessToken = window.localStorage.getItem('onDashboard.user.accessToken')
    const idToken = window.localStorage.getItem('onDashboard.user.idToken')

    if (givenName && accessToken && idToken) {
      return { givenName, accessToken, idToken }
    }
  }

  private fetchCalendarEvents() {
    if (!process.env.GATSBY_GOOGLE_CALENDAR_ID) {
      console.error('Please set the `GATSBY_GOOGLE_CALENDAR_ID` environment variable to a comma-separated list of Google Calendar IDs to use.') // tslint:disable-line:no-console
      return
    }

    const user = this.getUser()

    if (!user) {
      this.setState({ showLogin: true })
      return
    }

    this.setState({ isLoading: true })

    const headers = { Authorization: `Bearer ${user.accessToken}` }

    const calendarIDs = process.env.GATSBY_GOOGLE_CALENDAR_ID.split(',')
    const calendarURLs = calendarIDs.map(id => this.getRequestURLFromCalendarID(id))
    const calendarRequests = calendarURLs.map(url => fetch(url, { headers }))

    return Promise.all(calendarRequests)
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(responses => {
        responses.forEach(response => {
          if (response.error) { throw response }
        })

        return responses
      })
      .then(responses => responses.map<GoogleCalendarEvents>((response, index) => ({
        ...response,
        calendarID: calendarIDs[index],
      })))
      .then(flattenAndSortCalendars)
      .then(getCalendarEventsByMonthAndDay)
      .then(calendarEventsByMonthAndDay => this.setState({ calendarEventsByMonthAndDay }))
      .catch(error => {
        if (error.error && error.error.code === 401) {
          this.setState({ showLogin: true })
        } else {
          // No need to do anything in particular other than log the response
          console.error(error) // tslint:disable-line:no-console
        }
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  private getRequestURLFromCalendarID(calendarID: string): string {
    return `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`
      + '?timeMin=' + new Date().toISOString()
      + '&timeMax=' + moment().add(CALENDAR_TOTAL_DAYS, 'days').toISOString()
      + '&orderBy=starttime'
      + '&singleEvents=true'
  }
}

export const Consumer = Context.Consumer

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type Subtract<T, K> = Omit<T, keyof K>

interface InjectedGoogleServiceProps {
  user?: User
}

export const withGoogleService = <P extends InjectedGoogleServiceProps>(WrappedComponent: React.ComponentType<P>) => {
  const GoogleServiceHOC = (props: Subtract<P, InjectedGoogleServiceProps>) => (
    <Consumer>
      {({ getUser }) => <WrappedComponent user={getUser()} {...props as P} />}
    </Consumer>
  )

  return GoogleServiceHOC
}
