import styled from 'styled-components'

import { Breakpoint } from '@lib/layout'

export const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    margin-bottom: 0;
  }
`

export const H1 = styled.h1`
  color: white;
  font-family: 'Neutraface Slab';
  font-size: 1.5rem;
  line-height: 1.75rem;
  height: auto;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    height: 3.5rem;
  }
`

export const Header = styled.header`
  padding: 1.25rem 0 1.5rem;
`

export const Img = styled.img`
  display: block;
  margin-right: 1.5rem;
  height: 42px;
  width: 21px;
  flex-shrink: 0;
`
