import { graphql, StaticQuery } from 'gatsby'
import moment from 'moment'
import * as React from 'react'

import background from './images/background.jpg'
import * as Styles from './styles'

interface Image {
  file: {
    url: string,
  },
}

interface BackgroundImageSet {
  title: string,
  startTime: string,
  endTime: string,
  images: Image[],
}

interface QueryResponse {
  allContentfulBackgroundImageSet: {
    edges: Array<{
      node: BackgroundImageSet,
    }>,
  },
}

const getBackgroundImageURLForTime = (sets: Array<{ node: BackgroundImageSet }>, currentTime: Date): string => {
  // There's a lot of nested object inspection, so we'll wrap this in a
  // try/catch just to be safe, and return a default image. This helps guard
  // against, for example, crashing if someone deletes all the background image
  // sets in Contentful.
  try {
    const matchingSets = sets
      .filter(set => {
        const currentTimeString = moment(currentTime).format('HH:mm')
        const currentTimeIsInRange = set.node.startTime <= currentTimeString && set.node.endTime >= currentTimeString

        if (currentTimeIsInRange) {
          return true
        }

        const rangeOverlapsMidnight = set.node.startTime > set.node.endTime
        if (rangeOverlapsMidnight && set.node.startTime <= currentTimeString) {
          return true
        }

        if (rangeOverlapsMidnight && set.node.endTime >= currentTimeString) {
          return true
        }

        return false
      })

    const randomSetIndex = Math.round(Math.random() * (matchingSets.length - 1))
    const matchingSet = matchingSets[randomSetIndex]
    const randomImageIndex = Math.round(Math.random() * (matchingSet.node.images.length - 1))

    return matchingSet.node.images[randomImageIndex].file.url
  } catch {
    return background
  }
}

const MainCSS = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulBackgroundImageSet {
          edges {
            node {
              title
              startTime
              endTime
              images {
                description
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={(data: QueryResponse) => (
      <Styles.MainCSS
        background={getBackgroundImageURLForTime(data.allContentfulBackgroundImageSet.edges, new Date())}
      />
    )}
  />
)

export default MainCSS
