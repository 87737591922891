import styled from "styled-components";

import { FontWeight } from "@lib/typography";

const CLOCKS: any[] = require('../clocks.json') // tslint:disable-line:no-var-requires

export const Li = styled.li`
  color: white;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 1px;
  width: ${(1 / CLOCKS.length) * 100}%;

  /**
   * Add a little spacing to account for irregularities in the transform
   * calculations. This ensures that, for example, the left side doesn't get
   * cut off after a transition.
   */
  padding-left: 5px;
`

export const City = styled.div`
  font-weight: ${FontWeight.Book};
  text-transform: uppercase;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

