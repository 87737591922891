import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Weather from '.'

interface Props {
  accuWeatherLocationKey: string,
}

interface QueryResult {
  allAccuWeatherConditions?: {
    edges: Array<{
      node: {
        LocationKey: string,
        WeatherText: string,
        WeatherIcon: number,
        Temperature: {
          Imperial: {
            Value: number,
          },
          Metric: {
            Value: number,
          },
        },
      },
    }>,
  },
}

const WeatherContainer = ({ accuWeatherLocationKey }: Props) => (
  <StaticQuery
    query={graphql`
      {
        allAccuWeatherConditions {
          edges {
            node {
              WeatherText
              WeatherIcon
              LocationKey
              Temperature {
                Imperial {
                  Value
                }
                Metric {
                  Value
                }
              }
            }
          }
        }
      }
    `}
    render={({ allAccuWeatherConditions }: QueryResult) => {
      if (!allAccuWeatherConditions) { return null }

      const conditions = allAccuWeatherConditions.edges
        .find(edge => edge.node.LocationKey === accuWeatherLocationKey)

      if (!conditions) { return null }

      return (
        <Weather
          text={conditions.node.WeatherText}
          icon={conditions.node.WeatherIcon}
          imperial={conditions.node.Temperature.Imperial.Value}
          metric={conditions.node.Temperature.Metric.Value}
        />
      )
    }}
  />
)

export default WeatherContainer
