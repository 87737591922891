import styled from 'styled-components'

import { HAIRLINE } from './utilities'

export enum FontFamily {
  Primary = '"Helvetica Neue", Helvetica, sans-serif',
  Secondary = '"Neutraface Slab", "Helvetica Neue", Helvetica, sans-serif',
}

export enum FontWeight {
  Book = 500,
  Demibold = 600,
  Bold = 700,
}

export const SectionHeading = styled.h2`
  font-family: ${FontFamily.Primary};
  font-size: 0.75rem;
  letter-spacing: 1px;
  line-height: 1.5rem;
  text-transform: uppercase;

  margin-bottom: 0.25rem;
`

export const ViewMoreLink = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 2.5rem;
  padding: 0 2rem 0 1.5rem;

  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L5 5L1 1" stroke="rgb(102,102,102)" stroke-width="1.5"/></svg>');
  background-repeat: no-repeat;
  background-position: right center;
  border-top: ${HAIRLINE};
  color: inherit;
  font-size: 0.75rem;
  letter-spacing: 0.8px;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-image: url('data:image/svg+xml;utf8,<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L5 5L1 1" stroke="rgb(34,34,34)" stroke-width="1.5"/></svg>');
  }
`
