import { Container, setConfiguration } from 'react-grid-system'
import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Breakpoint, ContainerWidth, GRID_COLUMNS, GUTTER_WIDTH } from '@lib/layout'
import GlobalCSS from '@components/GlobalCSS'
import * as GoogleService from '../GoogleService'
import Header from '../Header'

import accuWeatherLogo from './images/accuweather-logo.png'
import * as Styles from './styles'

setConfiguration({
  breakpoints: [Breakpoint.sm, Breakpoint.md, Breakpoint.lg, Breakpoint.xl],
  containerWidths: [ContainerWidth.sm, ContainerWidth.md, ContainerWidth.lg, ContainerWidth.xl],
  gridColumns: GRID_COLUMNS,
  gutterWidth: GUTTER_WIDTH,
})

const Layout = ({ title, children }: { title?: string, children: React.ReactNode }) => (
  <GoogleService.Provider>
    <Container>
      <GlobalCSS />
      <Styles.ExtraPaddingOnMobile>
        <Header title={title} />
        <main>{children}</main>
        <footer>
          <Styles.AccuWeatherLogo src={accuWeatherLogo} alt="The AccuWeather logo" />
        </footer>
      </Styles.ExtraPaddingOnMobile>
    </Container>
  </GoogleService.Provider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
