import * as React from 'react'

import ResetCSS from './ResetCSS'
import FontsCSS from './FontsCSS'
import MainCSS from './MainCSS'

const GlobalCSS = () => (
  <>
    <ResetCSS />
    <FontsCSS />
    <MainCSS />
  </>
)

export default GlobalCSS
