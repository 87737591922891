import styled, { css } from 'styled-components'

export enum Breakpoint {
  sm = 576,
  md = 992,
  lg = 1200,
  xl = 1440,
}

export enum ContainerWidth {
  sm = 540,
  md = 960,
  lg = 1140,
  xl = 1384,
}

export const MOBILE_BODY_MARGIN = 16
export const GRID_COLUMNS = 16
export const GUTTER_WIDTH = 24

export enum ZIndices {
  background = -2,
  accuWeatherLogo = -1,
}

export const CardCSS = css`
  background-color: rgba(247, 247, 247, 0.9);
  margin-bottom: 1.5rem;
`

export const Card = styled.section`
  ${CardCSS}
`

export const CardTitle = styled.h2`
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
`
