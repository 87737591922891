import * as originalMoment from 'moment'
import { extendMoment } from 'moment-range'

import { CALENDAR_TOTAL_DAYS } from '@components/Calendar/constants'
import { GoogleCalendarEvent, CalendarEventsByMonthAndDay, GoogleCalendarEvents, AllDayGoogleCalendarEvent } from './types'

const moment = extendMoment(originalMoment)

const getNewMonth = (monthName: string) => ({ month: monthName, days: [] })
const getNewDay = (dayOfMonth: number, dayOfWeek: string) => ({ dayOfMonth, dayOfWeek, events: [] })
const isAllDayEvent = (event: GoogleCalendarEvent): event is AllDayGoogleCalendarEvent => 'date' in event.start
const getStartDate = (event: GoogleCalendarEvent) => isAllDayEvent(event) ? event.start.date : event.start.dateTime
const getEndDate = (event: GoogleCalendarEvent) => isAllDayEvent(event) ? event.end.date : event.end.dateTime
const isSingleDayEvent = (event: GoogleCalendarEvent) =>
  moment(getStartDate(event)).format('YYYY-MM-DD') ===
    moment(getEndDate(event)).format('YYYY-MM-DD')

const eventMatchesDate = (event: GoogleCalendarEvent, date: originalMoment.Moment) => {
  const startDate = getStartDate(event)
  const endDate = getEndDate(event)

  if (isSingleDayEvent(event)) {
    if (moment(startDate).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
      return true
    }

    return false
  }

  const range = moment.range(moment(startDate), moment(endDate))
  return range.contains(date)
}

export const getCalendarEventsByMonthAndDay = (events: GoogleCalendarEvent[]): CalendarEventsByMonthAndDay => {
  const calendarEventsByMonthAndDay: CalendarEventsByMonthAndDay = []

  for (let i = 0; i < CALENDAR_TOTAL_DAYS; i++) {
    const date = moment().add(i, 'days')
    const month = date.format('MMMM')

    let currentMonth = calendarEventsByMonthAndDay[calendarEventsByMonthAndDay.length - 1]
    if (!currentMonth || currentMonth.month !== month) {
      currentMonth = getNewMonth(month)
      calendarEventsByMonthAndDay.push(currentMonth)
    }

    const eventDayOfMonth = date.toDate().getDate()
    let currentDay = currentMonth.days.find(day => day.dayOfMonth === eventDayOfMonth)
    if (!currentDay) {
      const eventDayOfWeek = moment(date).format('ddd')
      currentDay = getNewDay(eventDayOfMonth, eventDayOfWeek)
      currentMonth.days.push(currentDay)
    }

    for (const event of events) {
      const startDate = getStartDate(event)
      if (!eventMatchesDate(event, date)) {
        continue
      }

      currentDay.events.push({
        id: event.id,
        title: event.summary,
        date: new Date(startDate),
        url: event.htmlLink,
        allDayEvent: event.start.hasOwnProperty('date'),
        calendarID: event.calendarID,
      })
    }
  }

  return calendarEventsByMonthAndDay
}

export const flattenAndSortCalendars = (calendars: GoogleCalendarEvents[]): GoogleCalendarEvent[] => {
  return calendars
    .reduce<GoogleCalendarEvent[]>(
      (prev, calendar) => {
        const eventsWithCalendarID = calendar.items.map(event => ({
          ...event,
          calendarID: calendar.calendarID,
        }))
        return prev.concat(eventsWithCalendarID)
      },
      [],
    )
    .sort((a, b) => {
      const aDate = getStartDate(a)
      const bDate = getStartDate(b)
      return +new Date(aDate) - +new Date(bDate)
    })
}
