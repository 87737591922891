import { Col, Row } from 'react-grid-system'
import * as React from 'react'

import WorldClock from './WorldClock'

import * as GoogleService from '@components/GoogleService'
import * as Styles from './styles'

import logo from './images/logo.svg'
import { StaticQuery, graphql } from 'gatsby'
import GoogleSearch from '@components/GoogleSearch'

interface QueryReponse {
  contentfulConfig: {
    titleWithName: string,
    titleWithoutName: string,
  },
}

const Header = ({ title }: { title?: string }) => (
  <StaticQuery
    query={graphql`
      query HeaderConfigQuery {
        contentfulConfig {
          titleWithName
          titleWithoutName
        }
      }
    `}
    render={(data: QueryReponse) => (
      <Styles.Header>
        <Row align="start">
          <Col md={4}>
            <Styles.Heading>
              <Styles.Img src={logo} alt="The On logo" />

              <Styles.H1>
                {title}
                {!title && (
                  <GoogleService.Consumer>
                    {({ getUser }) => {
                      const user = getUser()
                      if (!user) {
                        return data.contentfulConfig.titleWithoutName
                      }
                      return data.contentfulConfig.titleWithName.replace('{name}', user.givenName)
                    }}
                  </GoogleService.Consumer>
                )}
              </Styles.H1>
            </Styles.Heading>
          </Col>

          <Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
            <GoogleSearch />
          </Col>

          <Col md={1} />

          <Col md={5}>
            <WorldClock />
          </Col>
        </Row>
      </Styles.Header>
    )}
  />
)

export default Header
