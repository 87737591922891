import styled from 'styled-components'

import { Breakpoint, ZIndices, GUTTER_WIDTH, MOBILE_BODY_MARGIN } from '@lib/layout'

export const AccuWeatherLogo = styled.img`
  width: 100px;
  display: block;
  margin-left: auto;
  margin-bottom: 1.5rem;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    margin: 0;
    z-index: ${ZIndices.accuWeatherLogo}
  }
`

export const ExtraPaddingOnMobile = styled.div`
  /* Ensure 16px horizontal padding on mobile */
  padding: 0 ${MOBILE_BODY_MARGIN - (GUTTER_WIDTH / 2 )}px;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    padding: 0;
  }
`
