import { createGlobalStyle } from 'styled-components'

import { FontFamily } from '@lib/typography'
import { ZIndices } from '@lib/layout'

export const MainCSS = createGlobalStyle<{ background: string }>`
* {
  box-sizing: border-box;
}

a {
  color: inherit;
  transition: color 0.25s;

  text-decoration: none;

  &:hover {
    color: #222;
  }
}

body {
  background: black;
  color: #666;
  font-family: ${FontFamily.Primary};
  letter-spacing: 0.2px;

  /* Make the background a separate element, so that it can be blurred */
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${ZIndices.background};

    width: calc(100% + 10px);
    height: calc(100% + 10px);

    background-attachment: fixed;
    background-image: url(${props => props.background});
    background-position: center center;
    background-size: cover;

    /**
    * ensure that the blurred edge doesn't show white underneath -- see
    * https://stackoverflow.com/a/42963980/974981
    */
    transform: scale(1.05);

    transition: filter 1s, opacity 1s;
  }
}

body, html {
  /* This ensures that the 'background-size: cover' above works */
  height: 100%;
}
`
