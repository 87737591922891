import styled from 'styled-components'

import { Breakpoint } from '@lib/layout'
import { CLOCKS_PER_PAGE } from './constants'

const CLOCKS: any[] = require('./clocks.json') // tslint:disable-line:no-var-requires

export const Section = styled.section`
  display: flex;
`

export const UlWrapper = styled.div`
  overflow: auto;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    overflow: hidden;
  }
`

export const Ul = styled.ul<{ page?: number }>`
  display: flex;
  flex-grow: 1;

  width: ${(CLOCKS.length / CLOCKS_PER_PAGE) * 100}%;

  transition: transform 0.25s ease-in-out;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    ${props => props.page && props.page > 1 ? `
      transform: translateX(-${((props.page - 1) / (CLOCKS.length / CLOCKS_PER_PAGE)) * 100}%);
    ` : ''}
  }
`

export const Arrow = styled.img`
  display: none;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    cursor: pointer;
    display: block;
    width: 24px;
    height: 24px;
    align-self: center;
  }
`
