import styled from 'styled-components'

export const Form = styled.form`
  background-color: rgba(247, 247, 247, 0.8);
  margin-bottom: 1.5rem;
`

export const Input = styled.input`
  appearance: none;
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1.5rem;
  width: 100%;

  &:focus {
    outline: none;
  }
`
