import moment from 'moment-timezone'
import React, { useState, useEffect } from 'react'

import * as Styles from "./styles"
import WeatherContainer from '../Weather/container';

const City: React.FC<{
  city: string,
  timezone: string,
  accuWeatherLocationKey: string
}> = ({ city, timezone, accuWeatherLocationKey }) => {
  const [formattedTime, setFormattedTime] = useState('')

  useEffect(() => {
    // Only set the formatted time once client-side JavaScript is running.
    // Otherwise, users will see a brief flash of the last server-side render,
    // before it gets updated client-side to the correct current time.
    setFormattedTime(moment().tz(timezone).format('HH:mm'))
  }, [])

  return (
    <Styles.Li key={city}>
      <Styles.City>{city}</Styles.City>

      {formattedTime}<br />

      <WeatherContainer accuWeatherLocationKey={accuWeatherLocationKey} />
    </Styles.Li>
  )
}

export default City
