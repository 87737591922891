import * as React from 'react'

import * as Styles from './styles'
import { WEATHER_ICONS } from './images'

interface Props {
  metric: number,
  imperial: number,
  text: string,
  icon: number,
}

const Weather = ({ metric, imperial, text, icon }: Props) => (
  <div title={text}>
    {Math.round(metric)} C / {Math.round(imperial)} F
    {WEATHER_ICONS[icon] && (
      <Styles.Img
        src={WEATHER_ICONS[icon]}
        alt={`An icon representing the weather condition "${text}"`}
      />
    )}
  </div>
)

export default Weather
